import * as React from "react";
import { withPrefix } from "gatsby";
import { Helmet } from "react-helmet";
import background from "../../images/services/securite-incendie.jpg";
import "../../scss/style.scss";
import Banner from "../../components/Banner";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import ServicesListBasic from "../../components/services/ServicesListBasic";
import ContactUs from "../../components/buttons/ContactUs";

const SecuriteIncendie = () => {
  return (
    <main className="page">
      <title>
        Société de sécurité spécialisée dans votre protection - Services -
        Sécurité Incendie
      </title>
      <div id="wrapper" className="wrapper">
        <Header />
        <Banner
          category="Services"
          title="Sécurité Incendie"
          content=""
          background={background}
          position="left"
        />
        <div className="container pt-4 pt-md-10 pb-4 pb-md-10">
          <div className="row justify-content-start">
            <div className="col-md-8">
              <div className="service service-single">
                <div className="content">
                  <h1>
                    Services de sécurité incendie pour la protection de votre
                    entreprise
                  </h1>
                  <p>
                    Nous proposons des services de sécurité incendie complets
                    pour protéger votre entreprise contre les risques
                    d'incendie. La sécurité incendie est une préoccupation
                    majeure pour tout propriétaire d'entreprise et nous nous
                    engageons à fournir des{" "}
                    <em>solutions de sécurité personnalisées</em>
                    pour répondre aux besoins spécifiques de nos clients et
                    prévenir les départs de feu.
                  </p>
                  <h2>
                    Installation et maintenance de systèmes de sécurité incendie
                  </h2>
                  <p>
                    Nous installons et entretenons des{" "}
                    <em>systèmes de sécurité incendie</em> pour garantir la
                    sécurité de votre entreprise. Nous proposons une large gamme
                    de solutions de sécurité incendie, y compris des détecteurs
                    de fumée, des extincteurs, des alarmes incendie et des
                    systèmes de suppression d'incendie. Nous nous engageons à
                    fournir des solutions de sécurité incendie fiables pour
                    assurer la sécurité de votre entreprise.
                  </p>
                  <h2>Plan de prévention incendie</h2>
                  <p>
                    Nous proposons des{" "}
                    <em>services de planification de la prévention incendie</em>{" "}
                    pour garantir que votre entreprise est bien préparée en cas
                    d'incendie. Nous travaillons avec vous pour élaborer un plan
                    de prévention incendie personnalisé qui répond à vos besoins
                    spécifiques. Notre objectif est de garantir que votre
                    entreprise est bien préparée en cas d'incendie.
                  </p>
                  <h2>Formation à la sécurité incendie</h2>
                  <p>
                    Nous proposons une <em>formation à la sécurité incendie</em>{" "}
                    pour votre personnel afin de garantir que tout le monde est
                    bien préparé en cas d'incendie. Notre formation porte sur la
                    prévention incendie, l'utilisation des extincteurs et la
                    sécurité incendie en général. Notre objectif est de{" "}
                    <em>garantir que votre entreprise est bien préparée</em> en
                    cas d'incendie et que tous vos employés savent comment
                    réagir en cas d'urgence.
                  </p>
                  <h2>Inspection et évaluation des risques incendie</h2>
                  <p>
                    Nous effectuons des inspections et des évaluations des
                    risques incendie pour garantir la sécurité de votre
                    entreprise. Ces inspections régulières permettent de
                    détecter les risques potentiels et de proposer des solutions
                    adaptées pour les éliminer. Notre objectif est de garantir
                    que votre entreprise est aussi sûre que possible contre les
                    risques d'incendie.
                  </p>
                  <p>
                    Nous sommes fiers de dire que nous offrons des services de
                    sécurité incendie de qualité pour protéger votre entreprise
                    contre les risques d'incendie. Nous sommes engagés à fournir
                    des solutions de sécurité personnalisées pour répondre aux
                    besoins spécifiques de nos clients. Contactez-nous dès
                    aujourd'hui pour discuter de vos besoins en matière de
                    sécurité incendie.
                  </p>
                </div>
              </div>
              <div className="row justify-content-center pt-2">
                <ContactUs theme="black" />
              </div>
            </div>
            <div className="col-md-3 offset-md-1 pt-4">
              <h3>Nos prestations :</h3>
              <ServicesListBasic />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </main>
  );
};
export default SecuriteIncendie;

import * as React from "react";
import { Link } from "gatsby";
import ServiceData from "../../data/ServicesData";
import ServiceBasic from "./ServiceBasic";

// markup
const ServicesListBasic = () => {
  return (
    <div className="pt-1">
      {ServiceData.map((service) => (
        <div className="row">
          <ServiceBasic {...service} />
        </div>
      ))}
    </div>
  );
};

export default ServicesListBasic;

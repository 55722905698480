import * as React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

const ServiceBasic: React.FC<Service> = ({ ...service }: Service) => {
  const summaryExcerpt = `${service.summary.slice(0, 130).trimEnd()}...`;
  const isBrowser = typeof window !== "undefined";

  if (isBrowser) {
    const serviceLink = `${window.location.origin}/${service.link}`;
    return (
      <div className="col-12 pb-1">
        <div className="service service-summary">
          <div className="service-content">
            <h5>
              <span className="emoji" dangerouslySetInnerHTML={{ __html: service.emoji }}></span>
              <a href={serviceLink} className="lh-basic">
                {service.title}
              </a>
            </h5>
          </div>
        </div>
      </div>
    );
  }
};

export default ServiceBasic;

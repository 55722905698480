import * as React from "react";

// markup
const Banner = (props: any) => {
  return (
    <div
      id="hero"
      className="hero-image hero-image-setheight"
      style={{
        backgroundImage: `url(${props.background})`,
      }}
    >
      <div
        className={`container ${
          props.position === "right" ? "offset-md-6" : null
        }`}
      >
        <div className="hero-text">
          <span className="hero-section">{props.category}</span>
          <h1>{props.title}</h1>
          <p>{props.content}</p>
        </div>
      </div>
    </div>
  );
};

export default Banner;

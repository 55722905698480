import * as React from "react";

function ContactUs(props: any) {
  return (
    <div>
      <a
        href="tel:+33620645033"
        className={`${
          props.theme === "black"
            ? "button button-black"
            : "button button-white"
        }`}
      >
        Nous Contacter
      </a>
    </div>
  );
}

export default ContactUs;
